import React from "react";

interface Word {
  readonly definitions: string | null;
  readonly pinyin: string | null;
  readonly word: string | null;
}

export interface Props {
  // The GraphQL inference doesn't allow us to ensure that all the entries in the
  // array are non-null
  readonly words: readonly (Word | null)[];
}

export const KeyWords = ({ words }: Props) => {
  return (
    <table className="keyVocabulary">
      <tbody>
        {words.map((word) => (
          <>
            <tr>
              <td className="chinese">{word?.word}</td>
              <td>{word?.pinyin}</td>
              <td>{word?.definitions}</td>
            </tr>
          </>
        ))}
      </tbody>
    </table>
  );
};
