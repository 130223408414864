import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";

export interface Props {
  active?: boolean;
  to?: string;
  target?: string;
  title?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

// react-bootstrap isn't quite flexible enough to allow us to wrap a Gatsby
// Link inside a breadcrumb, so we have to duplicate some of that here.
export const GatsbyBreadcrumbLink = ({
  to,
  children,
  active,
  className,
}: Props) => {
  return (
    <li className={classNames("breadcrumb-item", className, { active })}>
      {active && children}
      {!active && to !== undefined && <Link to={to}>{children}</Link>}
    </li>
  );
};
