import React from "react";
import { graphql, PageProps, Link, HeadProps } from "gatsby";
import { Breadcrumb, Card, Col, Container, Row } from "react-bootstrap";
import YouTube from "react-youtube";

import Layout from "../../layouts/default";
import { useViewTracker } from "../../models/view-tracking";
import { GatsbyBreadcrumbLink } from "../../components/Breadcrumbs";
import PageIcon from "../../components/PageIcon";
import { KeyWords } from "../../components/KeyWords";

export const Head = ({ data }: HeadProps<Queries.VideosYamlBySlugQuery>) => {
  const title = data.videosYaml?.title;

  return (
    <>
      <title>Comprehensible Chinese Listening - {title} - MandarInput</title>
      <PageIcon />
    </>
  );
};

export const VideoPage = ({
  data,
}: PageProps<Queries.VideosYamlBySlugQuery>) => {
  const videoId = data.videosYaml?.videoId ?? null;
  const title = data.videosYaml?.title;
  const topics = data.videosYaml?.topics ?? [];
  const summary = data.videosYaml?.summary ?? "";
  const keyWords = data.videosYaml?.vocabulary?.keyWords;

  const { initialPosition, onReady, onPlay, onPause, onEnd } =
    useViewTracker(videoId);

  if (videoId === null) {
    return <p>That video no longer exists</p>;
  }

  return (
    <Layout>
      <Row>
        <Col>
          <Breadcrumb>
            <GatsbyBreadcrumbLink to="/">Home</GatsbyBreadcrumbLink>
            <GatsbyBreadcrumbLink active>{title}</GatsbyBreadcrumbLink>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Title>{title}</Card.Title>
            <Container>
              <YouTube
                videoId={videoId}
                className="youtubeContainer fullScreen"
                iframeClassName="embed"
                opts={{ playerVars: { start: initialPosition } }}
                onReady={onReady}
                onPlay={onPlay}
                onPause={onPause}
                onEnd={onEnd}
              />
            </Container>

            <Card.Body>
              <Container>
                <Row>
                  <Col md={8}>
                    {summary && (
                      <>
                        <h2>Summary</h2>
                        <p>{summary}</p>
                      </>
                    )}
                    {keyWords !== undefined && keyWords !== null && (
                      <>
                        <h3>Key vocabulary</h3>
                        <KeyWords words={keyWords} />
                      </>
                    )}
                  </Col>
                  <Col md={4}>
                    {topics.length > 0 && (
                      <p>
                        Topics:{" "}
                        {topics.map((topic, idx) => [
                          idx > 0 && ",",
                          <React.Fragment key="topic">
                            <Link to={`/?topics=${topic}`}>{topic}</Link>
                          </React.Fragment>,
                        ])}
                      </p>
                    )}
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default VideoPage;

export const pageQuery = graphql`
  query VideosYamlBySlug($slug: String!) {
    videosYaml(slug: { eq: $slug }) {
      videoId
      slug
      title
      topics
      summary
      vocabulary {
        keyWords {
          definitions
          pinyin
          word
        }
      }
    }
  }
`;
