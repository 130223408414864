import { useEffect, useRef, useState } from "react";
import { YouTubeEvent } from "react-youtube";
import type { YouTubePlayer } from "youtube-player/dist/types";

import { db } from "../db";

// Write a view record to the DB, or ovewrite an existing record.
const recordView = (
  videoId: string,
  finalPosition: number | "end",
  primaryKey?: number
): Promise<number> => {
  let result: Promise<number>;

  if (primaryKey !== undefined) {
    db.videoViews.update(primaryKey, { finalPosition });
    result = Promise.resolve(primaryKey);
  } else {
    result = db.videoViews
      .add({ videoId, when: new Date(), finalPosition })
      .then((insertedPrimaryKey) => {
        if (typeof insertedPrimaryKey === "number") {
          return insertedPrimaryKey;
        } else {
          throw new Error(
            `Unrecognized primary key type ${typeof insertedPrimaryKey} returned`
          );
        }
      });
  }

  if (window.analytics !== undefined) {
    window.analytics.track("Video played", { videoId, finalPosition });
  }

  if (window.plausible !== undefined) {
    window.plausible("Play video");
  }

  return result;
};

export interface ViewHandlers {
  initialPosition: number;

  onReady: (e: YouTubeEvent) => void;
  onPlay: () => void;
  onPause: () => void;
  onEnd: () => void;
}

// Custom hook for recording in the local indexedDB when
// and how much a user views a video
//
// We allow the video ID to be null, purely to make it easier
// to use this hoook in the case where the video ID may not be
// able to be determined, and we can't call the hook conditionally.
export const useViewTracker = (videoId: string | null): ViewHandlers => {
  // The position that the user was watching the video when they last stopped
  // watching it. This has to be loaded asynchronously.
  const [initialPosition, setInitialPosition] = useState(0);
  const [videoPlayed, setVideoPlayed] = useState(false);

  // Record the primary key of the inserted record. This tracker may send
  // multiple updates, but we want to collapse them into a single DB
  // record and not create lots of separate DB records unnecessarily.
  const [primaryKey, setPrimaryKey] = useState<number | undefined>(undefined);

  const player = useRef<YouTubePlayer | null>(null);

  // Idempotent function to record the current play state into the database.
  // This can be called multiple times and should appropriately collapse the
  // records into one record for the current session.
  const recordCurrentState = () => {
    if (videoId === null) {
      return;
    }

    if (videoPlayed && player.current !== null) {
      // The type declarations for youtube-player seem to be wrong, getCurrentTime
      // is reported as returning a promise, but it doesn't.
      const currentTime = player.current.getCurrentTime() as unknown;
      const duration = player.current.getDuration() as unknown;

      if ((currentTime as number) > (duration as number) - 10) {
        recordView(videoId, "end", primaryKey).then((pk) => setPrimaryKey(pk));
      } else {
        recordView(videoId, Math.round(currentTime as number), primaryKey).then(
          (pk) => setPrimaryKey(pk)
        );
      }
    }
  };

  useEffect(() => {
    if (videoId === null) {
      return;
    }

    // We want to find the most recent view of this video, and take the play
    // position at which the user stopped watching it and set that as the
    // starting position for the next view.
    db.videoViews
      .where("videoId")
      .equals(videoId)
      .reverse()
      .sortBy("when")
      .then((records) => {
        if (records.length > 0) {
          const finalPosition = records[0].finalPosition;

          // The position may not have been recorded (if it's an outdated DB)
          // If they got to the end, don't set anything so that they can
          // start again from the beginning.
          if (finalPosition !== undefined && finalPosition !== "end") {
            setInitialPosition(finalPosition);
          }
        }
      });

    window.addEventListener("beforeunload", recordCurrentState);

    return () => {
      window.removeEventListener("beforeunload", recordCurrentState);
      recordCurrentState();
    };
  }, [videoId, videoPlayed, player.current]);

  return {
    initialPosition,
    onReady: (e) => {
      player.current = e.target;
    },
    onPlay: () => {
      setVideoPlayed(true);
    },
    onPause: () => {
      recordCurrentState();
    },
    onEnd: () => {
      recordCurrentState();
    },
  };
};
